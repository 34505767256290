<template>
    <div>
        <b-card
            bg-variant="danger"
            text-variant="white"
            header="Shrnutí objednávky"
            class="text-center"
            v-if="platba_checked && platba_error != null"
        >
            <div class="w-100 text-center">
                <br><br><br>
                <h2>
                    Pravděpodobně došlo k porušení integrity dat.
                </h2>
                <br>
                <p>
                    <em>
                        Příčina: {{ platba_error.message }}
                    </em>
                </p>
                <br><br>
                <p>
                    Kontaktujte prosím podporu.
                    <br>
                    ...
                </p>
                <b-button
                    variant="primary"
                    class="mb-2 btn-sm-block"
                    :to="{ name: 'default-view', params: { }}"
                >
                    {{ $t("Zpět") }}
                </b-button>
                <br><br><br>
            </div>
        </b-card>

        <b-card
            bg-variant="light"
            text-variant="black"
            header="Shrnutí objednávky"
            class="text-center"
            v-if="platba_checked && platba_error == null"
        >
            <div class="w-100 text-center">
                <h2>
                    Vaše platba kartou nebo online převodem byla úspěšně provedena.
                </h2>
                <br>
                <p>
                    Na vaši emailovou adresu bude zaslán email se souhrnými informacemi k objednávce.
                </p>
                <br>
                <h3>Souhrné informace k objednávce</h3>
                <p>
                    Variabilní symbol: {{ objednavka.variabilni_symbol }}
                </p>
                <br>
                <p>
                    Pokud chcete pokračovat další objednávkou, klikněte zde
                </p>
                <b-button
                    variant="primary"
                    class="mb-2 btn-sm-block"
                    :to="{ name: 'default-view', query: { path: path }}"
                >
                    Další objednávka
                </b-button>
                <br><br>
                <p>
                    Děkujeme za váš nákup.
                    <br>
                    Rancherské speciality z Jestřebic
                </p>
            </div>
        </b-card>
    </div>
</template>

<script>
import * as constants from '@/store/constants.js'
import { state, mutations } from '@/store/index.js'

export default {
    components: { },

    computed: {
        platba_checked( ) {
            return state.platba_checked
        },

        platba_error( ) {
            return state.platba_error
        }
    },

    data( ) {
        return {
            objednavka: {
                variabilni_symbol: null
            },
            path: null
        }
    },

    mounted( ) {
        let params = this.$route.query
        //console.log("PaidView.params", params)

        this.checkPayment({
            objednavka_id: params.refId,
            platba_id: params.id,
            stav_platby_id: constants.STAV_PLATBY_ZAPLACENA
        })
    },

    methods: {
        checkPayment(platbaData) {
            mutations.checkPayment(platbaData)
                .then((response) => {
                    //console.log("PaidView.checkPayment( )", response)

                    this.objednavka = response.objednavka

                    this.path = response.path
                })
                .catch(errors => {
                    mutations.processPaymentDataErrors(errors)
                })
        }
    }
}
</script>

<style scoped>
</style>